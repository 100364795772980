import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastComponent } from "../../shared/toast/toast.component";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { User } from "../../shared/models/user.model";

@Component({
  selector: "app-qr-code",
  templateUrl: "./qrcode.component.html",
  styleUrls: ['./qrcode.component.css'],
})
export class qrCodeComponent implements OnInit {
  users: User[] = [];
  isLoading = true;
  qrImage;
  id: string = "";
  user: User = null;
  isTheHostKiosk: Boolean = false
  alerted: Boolean = false

  constructor(
    public auth: AuthService,
    public toast: ToastComponent,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  checkIfWordExistsInRoute(word: string): boolean {
    const urlSegments = this.route.snapshot.url;
    return urlSegments.some(segment => segment.path === word);
  }

  assetLoaded() {
    if (this.isTheHostKiosk) {
      this.closeThisPageAfterDelay()
    }
  }

  ngOnInit() {
    if (window.location.href.match(/kiosk/gi)) {
      this.isTheHostKiosk = true
    }

    const id = this.route.snapshot.paramMap.get("_id");
    this.getUsers_id(id);
  }

  showNotification(message, duration) {
    var notification = document.getElementById('notification');
    notification.innerHTML = message;
    notification.style.display = 'block';

    setTimeout(function () {
      notification.style.display = 'none';
    }, duration);
  }


  getUsers_id(_id: string) {
    this.userService.getUsers_id(_id).subscribe((user) => {
      console.log("user", user);
      this.user = {
        ...user, qrImage: this.userService.getQRImageURL(
          user.data.phone,
        )
      };
    });
  }

  closeThisPageAfterDelay() {

    if (this.alerted) return

    this.alerted = true

    setTimeout(() => window.close(), 10000)

    window.print();

    setTimeout(() => {
      this.showNotification('Welcome! Please collect your badge!', 5000)
    }, 1500)
  }
}
