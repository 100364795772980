import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ToastComponent } from "../../shared/toast/toast.component";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { User } from "../../shared/models/user.model";
import { UploadService } from '../../services/upload.service';

@Component({
  selector: "app-import-csv",
  templateUrl: "./import.csv.component.html",
})
export class ImportCSV {
    file: File = null;

    constructor(
      private uploadService: UploadService,
    ){

    }

    onFilechange(event: any) {
      console.log(event.target.files[0])
      this.file = event.target.files[0]
    }

    upload() {
      if (this.file) {
        this.uploadService.uploadfile(this.file).subscribe(resp => {
          alert("Uploaded")
        })
      } else {
        alert("Please select a file first")
      }
    }



}
