export class User {
  _id?: string;
  name?: string;
  email?: string;
  role?: string;
  phone?: string;
  password?: string;
  qrImage?: string;
  data?: {
    phone: string,
  };
  isActive?: string;
  attendance?: Array<Date>
  checkedInTime: Date
}
