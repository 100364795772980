import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-create-dialog-attendees',
  templateUrl: './create.dialog.attendees.component.html',
  styleUrls: ['create.dialog.attendees.component.css'],
})
export class CreateDialogAttendees implements OnInit {

  attendeesForm: FormGroup;

  agendaName = new FormControl("", []);
  designation = new FormControl("", []);
  address = new FormControl("", []);
  city = new FormControl("", []);
  category = new FormControl("", []);
  firstName = new FormControl("", [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);
  lastName = new FormControl("", [
    Validators.minLength(2),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);
  phone = new FormControl("", [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(100),
  ]);
  email = new FormControl("", [
    Validators.minLength(3),
    Validators.maxLength(100),
  ]);
  lunchWith = new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public auth: AuthService,
    public toast: ToastComponent,
    private userService: UserService
  ) { }

  parseInputError(errors: any): string {
    if (errors) {
      if (errors.required) {
        return 'This field is required.';
      } else if (errors.minlength) {
        const requiredLength = errors.minlength.requiredLength;
        const actualLength = errors.minlength.actualLength;
        return `Minimum length should be ${requiredLength}, but you entered ${actualLength}.`;
      } else if (errors.maxlength) {
        const requiredLength = errors.maxlength.requiredLength;
        const actualLength = errors.maxlength.actualLength;
        return `Maximum length should be ${requiredLength}, but you entered ${actualLength}.`;
      } else if (errors.pattern) {
        return 'Invalid input format.';
      }
      return 'Invalid input.';
    }
    return '';
  }

  ngOnInit() {
    this.attendeesForm = this.formBuilder.group({
      agendaName: this.agendaName,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      designation: this.designation,
      address: this.address,
      city: this.city,
      category: this.category,
    });
  }

  inputError(name: string) {
    return (this[name] && this[name].touched && this[name].errors && this.parseInputError(this[name].errors)) || false;
  }

  onSubmit() {
    this.userService.onSubmit(this.attendeesForm.value).subscribe(
      (res) => {
        this.toast.setMessage("Attendees successfully Added", "success");
        this.router.navigate(["/attendee"]);
      },
      (error) => this.toast.setMessage("Attendees already Exists!", "danger")
    );
  }
}
