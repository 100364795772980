import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from '../shared/models/user.model';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  onSubmit(user: User): Observable<User> {
    return this.http.post<User>(`/api/v1/users/import`, user);
  }

  onUpdate(email: User): Observable<User[]> {
    return this.http.put<User[]>(`/api/v1/users/by-email/${email.email}`, email);
  }

  register(user: User): Observable<User> {
    return this.http.post<User>('/api/v1/useri', user);
  }

  login(credentials): Observable<any> {
    return this.http.post('/api/v1/login', credentials);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>('/api/v1/users');
  }

  getCheckingUpdate(): Observable<User[]> {
    return this.http.get<User[]>('/api/v1/usersapp/checked-in');
  }

  getExiviters(): Observable<User[]> {
    return this.http.get<User[]>('/api/v1/usersi');
  }

  countUsers(): Observable<number> {
    return this.http.get<number>('/api/v1/users/count');
  }

  getUser(user: User): Observable<User> {
    return this.http.get<User>(`/api/v1/useri/${user._id}`);
  }

  getUsers_id(_id: string): Observable<User> {
    return this.http.get<User>(`/api/v1/users/${_id}`);
  }

  getVisitorbyId(id): Observable<User[]> {
    return this.http.get<User[]>(`/api/v1/user/${id}`);
  }

  getCSV(): Observable<User> {
    return this.http.get<User>(`/api/v1/export`);
  }

  getCompleteExiviters(params): Observable<User[]> {
    return this.http.get<User[]>(`/api/v1/usersapp?${params.toString()}`);
  }

  getCheckedInAttendees(): Observable<User[]> {
    return this.http.get<User[]>('/api/v1/usersapp/checked-in')
  }

  getQRImageURL(data: string): string {
    console.log(data, 'data getQRImageURL')
    console.log('-----------rahul------------')
    return `https://api.qrserver.com/v1/create-qr-code/?data=${data}`
  }

  editUser(user: User): Observable<any> {
    return this.http.put(`/api/v1/useri/${user._id}`, user, { responseType: 'text' });
  }

  emailList(email): Observable<any> {
    return this.http.post(`/api/v1/emailList`, email);
  }

  emailUserList(email, id): Observable<any> {
    Object.assign(email, { id: id });
    return this.http.post(`/api/v1/email`, email);
  }

  deleteUser(user: User): Observable<any> {
    return this.http.delete(`/api/v1/useri/${user._id}`, { responseType: 'text' });
  }

}
