import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastComponent } from '../shared/toast/toast.component';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../shared/models/user.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  users: User[] = [];
  isLoading = true;
  exitibers: User[] = [];

  constructor(public auth: AuthService,
              public toast: ToastComponent,
              private userService: UserService,
              private router: Router) { }

  ngOnInit() {
    this.getUsers();
    this.getExcitiber();

  }

  getUsers() {
    this.userService.getUsers().subscribe(
      data => data.map(visiter => {
        if (visiter.email){
          this.users.push(visiter);
          // this.exitibers = data
        }
      }),
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  getCSV() {
    this.userService.getCSV().subscribe(
      data => console.log(data),
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  getExcitiber() {
    this.userService.getExiviters().subscribe(
      data => {
        data.map(users => {
          if(this.auth.currentUser._id !== users._id) {
            this.exitibers.push(users);
          }
        });
      },
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  deleteUser(user: User) {
    user.isActive = 'false';
    // console.log(user);
    if (window.confirm('Are you sure you want to deactivate ' + user.name + '?')) {
      this.userService.editUser(user).subscribe(
        data => this.toast.setMessage('user disabled successfully.', 'success'),
        error => console.log(error),
        () => {
          this.exitibers = [];
          this.getExcitiber();
        }
      );
    }
  }

  removeUser(user: User) {
    if (window.confirm('Are you sure you want to delete ' + user.name + '?')) {
      this.userService.deleteUser(user).subscribe(
        data => this.toast.setMessage('user deleted successfully.', 'success'),
        error => console.log(error),
        () => {
          this.exitibers = [];
          this.getExcitiber();
        }
      );
    }
  }

  ActiveUser(user: User) {
    user.isActive = 'true';
    if (window.confirm('Are you sure you want to Activate ' + user.name + '?')) {
      this.userService.editUser(user).subscribe(
        data => this.toast.setMessage('user enabled successfully.', 'success'),
        error => console.log(error),
        () => {
          this.exitibers = [];
          this.getExcitiber();
        }
      );
    }
  }

  redirect() {
    this.router.navigate(['./register']);
  }

}
