import { Component, OnInit } from '@angular/core';
import { ToastComponent } from '../shared/toast/toast.component';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../shared/models/user.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  users: User[] = [];
  isLoading = true;
  exhibiters = {};

  emailForm: FormGroup;
  email = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(100)
  ]);

  constructor(private auth: AuthService,
              public toast: ToastComponent,
              private formBuilder: FormBuilder,
              private userService: UserService) {}

  ngOnInit() {
    this.getUsers();
    this.getExhibiters();

    this.emailForm = this.formBuilder.group({
      email: this.email
    });
  }
  setClassEmail() {
    return { 'has-danger': !this.email.pristine && !this.email.valid };
  }

  sendEmail() {
    this.userService.emailList(this.emailForm.value).subscribe(
      data => {
        this.emailForm.reset();
        this.toast.setMessage('Mail has been sent successfully.', 'success');
      },
      error => console.log(error),
    );
  }
  getUsers() {
    this.userService.getUsers().subscribe(
      data => data.map(visiter => {
        if (visiter.email) {
          this.users.push(visiter);
          // this.exitibers = data
        }
      }),
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  getExhibiters() {
    this.userService.getExiviters().subscribe(
      data => data.map(visiter => {
        if (visiter.email) {
          this.exhibiters[visiter._id] = visiter.name;
          // this.exitibers = data
        }
      }),
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  getCSV() {
    this.userService.getCSV().subscribe(
      data => console.log(data),
      error => console.log(error),
      () => this.isLoading = false
    );
  }
}
