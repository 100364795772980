import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { UserService } from "../../services/user.service";
import { ToastComponent } from "../../shared/toast/toast.component";
import { AuthService } from "../../services/auth.service";
import { User } from "../../shared/models/user.model";

@Component({
  selector: "app-update-attendees",
  templateUrl: "./updateattendees.component.html",
})
export class UpdateAttendees implements OnInit {
  users: User[] = [];
  isLoading = true;
  id: string = "";
  user: User = null;

  updateAttendeesForm: FormGroup;
  agendaName = new FormControl("", []);
  designation = new FormControl("", []);
  address = new FormControl("", []);
  city = new FormControl("", []);
  category = new FormControl("", []);
  firstName = new FormControl("", [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);
  lastName = new FormControl("", [
    Validators.minLength(2),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);
  phone = new FormControl("", [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(100),
  ]);
  email = new FormControl("", [
    Validators.minLength(3),
    Validators.maxLength(100),
  ]);
  lunchWith = new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(30),
    Validators.pattern("[a-zA-Z0-9_-\\s]*"),
  ]);
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public toast: ToastComponent,
    private userService: UserService
  ) {}

  parseInputError(errors: any): string {
    if (errors) {
      if (errors.required) {
        return 'This field is required.';
      } else if (errors.minlength) {
        const requiredLength = errors.minlength.requiredLength;
        const actualLength = errors.minlength.actualLength;
        return `Minimum length should be ${requiredLength}, but you entered ${actualLength}.`;
      } else if (errors.maxlength) {
        const requiredLength = errors.maxlength.requiredLength;
        const actualLength = errors.maxlength.actualLength;
        return `Maximum length should be ${requiredLength}, but you entered ${actualLength}.`;
      } else if (errors.pattern) {
        return 'Invalid input format.';
      }
      return 'Invalid input.';
    }
    return '';
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("_id");
    this.getUsers_id(id);
    //this.getUsers();

    this.updateAttendeesForm = this.formBuilder.group({
      agendaName: this.agendaName,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      designation: this.designation,
      address: this.address,
      city: this.city,
      category: this.category,
    });
  }

  inputError(name: string) {
    return (this[name] && this[name].touched && this[name].errors && this.parseInputError(this[name].errors)) || false;
  }


  getUsers_id(_id: string) {
    this.userService.getUsers_id(_id).subscribe((user) => {
      this.user = user;
    });
  }
  // getUsers() {
  //   this.userService.getCompleteExiviters().subscribe(
  //     (data) =>
  //       data.map((visiter) => {
  //         if (visiter.email) {
  //           var visitorInfo = {
  //             ...visiter,
  //           };

  //           this.users.push(visitorInfo);
  //         }
  //       }),
  //     (error) => console.log(error),

  //     () => (this.isLoading = false)
  //   );
  // }

  onUpdate() {
    this.userService.onUpdate(this.updateAttendeesForm.value).subscribe(
      (res) => {
        this.toast.setMessage("Attendees Updated successfully", "success");
        this.router.navigate(["/attendee"]);
      },
      (error) => this.toast.setMessage("Attendees already Exists!", "danger")
    );
  }
}
