import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ToastComponent } from "../shared/toast/toast.component";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { User } from "../shared/models/user.model";
import { DatePipe } from "@angular/common";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: "app-attendees",
  templateUrl: "./attendees.component.html",
  styleUrls: ["./attendees.scss"],
  providers: [DatePipe],
})
export class AttendeesComponent implements OnInit {
  users: User[] = [];
  isLoading = true;
  selectedRegistrationType = '';
  selectedGateName = '';
  registrationType = ['On Spot', 'Using CMS', 'CSV Import'];
  gateNumber = [
    "Plenary Session",
    "Wireless Track",
    "Wireline and High Speed Digital Track",
    "Software Track",
    "Automotive Track",
    "Network Test Track",
    "Lunch",
    "Giveaways"
  ];

  constructor(
    private router: Router,
    public auth: AuthService,
    public toast: ToastComponent,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    let params = new HttpParams();
    params = params.append('registrationType',this.selectedRegistrationType);
    params = params.append('gateNumber',this.selectedGateName);
    this.users = [];
    this.userService.getCompleteExiviters(params).subscribe(
      (data) =>
        data.map((visiter) => {
          if (visiter.email) {
            this.users.push(visiter);
          }
        }),
      (error) => console.log(error),
      () => (this.isLoading = false)
    );
  }

  redirect() {
    this.router.navigate(["./addAttendees"]);
  }

  printQRcode(_id: string) {
    console.log(`/qrcode?id=${_id}`);

    this.router.navigate([`/qrcode?id=${_id}`])
  }

  importcsv() {
    this.router.navigate(["./importcsv"]);
  }
  exportcsv() {

    let params = new HttpParams();
    if(this.selectedRegistrationType)
    params = params.append("registrationType", this.selectedRegistrationType);
    if(this.selectedGateName)
    params = params.append("gateNumber", this.selectedGateName);
    const baseUrl = '/api/v1/cexport';
    const urlWithQueryParams = `${baseUrl}?${params.toString()}`;
    this.selectedGateName = this.selectedRegistrationType = '';
    window.open(urlWithQueryParams, '_blank')
  }

  isCheckedIn(user: User): Boolean {

    if (user.checkedInTime) {
      const time = new Date(user.checkedInTime)
      return time.getTime() !== 0
    } else {
      return false
    }
  }

}

