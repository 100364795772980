// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Services
import { AuthGuardLogin } from './services/auth-guard-login.service';
import { AuthGuardAdmin } from './services/auth-guard-admin.service';
// Components
import { AboutComponent } from './about/about.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from "./users/users.component";
import { AttendeesComponent } from "./attendees/attendees.component";
import { NotFoundComponent } from './not-found/not-found.component';
import { CreateDialogAttendees } from './attendees/create-attendees/create.dialog.attendees.component';
import { qrCodeComponent } from './attendees/qrcode/qrcode.component';
import { ImportCSV } from './attendees/import-csv/import.csv.component';
import { CheckingComponent } from './checking/checking.component';
import { UpdateAttendees } from './attendees/update-attendees/updateattendees.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'lead', component: AboutComponent, canActivate: [AuthGuardLogin] },
  {
    path: 'register', component: RegisterComponent, canActivate: [AuthGuardAdmin]
  },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardLogin] },
  { path: 'logout', component: LogoutComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuardLogin] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardAdmin] },
  { path: 'attendee', component: AttendeesComponent, canActivate: [AuthGuardAdmin] },
  { path: 'addAttendees', component: CreateDialogAttendees, canActivate: [AuthGuardAdmin] },
  { path: 'updatedetail/:_id', component: UpdateAttendees, canActivate: [AuthGuardAdmin] },
  { path: 'checking', component: CheckingComponent },
  { path: 'qrcode/:_id', component: qrCodeComponent },
  { path: 'qrcode/for-kiosk/:_id', component: qrCodeComponent },
  { path: 'importcsv', component: ImportCSV, canActivate: [AuthGuardAdmin] },
  { path: 'notfound', component: NotFoundComponent },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
