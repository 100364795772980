import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ToastComponent } from "../shared/toast/toast.component";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { User } from "../shared/models/user.model";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-checking",
  templateUrl: "./checking.component.html",
  providers: [DatePipe],
})
export class CheckingComponent implements OnInit {
  users: User[] = [];
  isLoading = true;

  constructor(
    private router: Router,
    public auth: AuthService,
    public toast: ToastComponent,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getCheckingUpdate();
  }

  getCheckingUpdate() {
    this.userService.getCheckedInAttendees().subscribe(
      (data) =>
        data.map((visiter) => {
          if (visiter.email) {
            this.users.push(visiter);
          }
        }),
      (error) => console.log(error),
      () => (this.isLoading = false)
    );
  }

  isCheckedIn(user: User): Boolean {

    if (user.checkedInTime) {
      const time = new Date(user.checkedInTime)
      return time.getTime() !== 0
    } else {
      return false
    }
  }
}
