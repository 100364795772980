import { Component, OnInit } from '@angular/core';
import { User } from "../shared/models/user.model";
import { AuthService } from "../services/auth.service";
import { ToastComponent } from "../shared/toast/toast.component";
import { UserService } from "../services/user.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  isLoading = true;
  link: string;
  emailForm: FormGroup;
  email = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(100)
  ]);

  constructor(public auth: AuthService,
              public toast: ToastComponent,
              private userService: UserService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.link = "api/v1/userexport/" + this.auth.currentUser._id;
    this.getUsers();
    this.emailForm = this.formBuilder.group({
      email: this.email
    });
  }

  getUsers() {
    this.userService.getVisitorbyId(this.auth.currentUser._id).subscribe(
      data => this.users = data,
      error => console.log(error),
      () => this.isLoading = false
    );
  }

  deleteUser(user: User) {
    if (window.confirm('Are you sure you want to delete ' + user.name + '?')) {
      this.userService.deleteUser(user).subscribe(
        data => this.toast.setMessage('user deleted successfully.', 'success'),
        error => console.log(error),
        () => this.getUsers()
      );
    }
  }

  setClassEmail() {
    return { 'has-danger': !this.email.pristine && !this.email.valid };
  }

  sendEmail() {
    this.userService.emailUserList(this.emailForm.value, this.auth.currentUser._id).subscribe(
      data => {
        this.emailForm.reset();
        this.toast.setMessage('Mail has been sent successfully.', 'success')
      },
      error => console.log(error),
    );
  }
}
