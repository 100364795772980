// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { JwtModule } from "@auth0/angular-jwt";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
// Services
import { UserService } from "./services/user.service";
import { AuthService } from "./services/auth.service";
import { AuthGuardLogin } from "./services/auth-guard-login.service";
import { AuthGuardAdmin } from "./services/auth-guard-admin.service";
// Components
import { AppComponent } from "./app.component";
import { AboutComponent } from "./about/about.component";
import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { AccountComponent } from "./account/account.component";
import { AdminComponent } from "./admin/admin.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { UsersComponent } from "./users/users.component";
import { AttendeesComponent } from "./attendees/attendees.component";
import { CreateDialogAttendees } from './attendees/create-attendees/create.dialog.attendees.component';
import { qrCodeComponent } from './attendees/qrcode/qrcode.component';
import { ImportCSV } from './attendees/import-csv/import.csv.component';
import { CheckingComponent } from './checking/checking.component';
import { UpdateAttendees } from './attendees/update-attendees/updateattendees.component';
import { ToastComponent } from "./shared/toast/toast.component";


export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    RegisterComponent,
    LoginComponent,
    LogoutComponent,
    AccountComponent,
    ToastComponent,
    AdminComponent,
    NotFoundComponent,
    UsersComponent,
    AttendeesComponent,
    CreateDialogAttendees,
    qrCodeComponent,
    ImportCSV,
    CheckingComponent,
    UpdateAttendees
  ],
  imports: [
    AppRoutingModule,
    // ToastComponent,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ['localhost:3000', 'localhost:4200']
      },
    }),
  ],
  providers: [AuthService, AuthGuardLogin, AuthGuardAdmin, UserService, ToastComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
